import { Modal as StyledModal } from "@mui/material";

interface Props {
  open: boolean;
  onClose: () => void;
  children: JSX.Element;
}

const Modal = ({ open, onClose, children }: Props) => {
  return (
    <StyledModal open={open} onClose={onClose}>
      {children}
    </StyledModal>
  );
};

export default Modal;
