import { Box } from "@mui/material";

import Text from "../Text/Text";

interface Props {
  text: string;
  isActive?: boolean;
}

const Chip = ({ text, isActive }: Props) => {
  return (
    <Box className={`Chip ${isActive && "isChipActive"}`}>
      <Text children={text} />
    </Box>
  );
};

export default Chip;
