import { Avatar, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import Text from "../../atoms/Text/Text";
import Modal from "../../atoms/Modal/Modal";
import CROSS from "../../../assets/images/CROSS.svg";
import INFO from "../../../assets/images/INFO.svg";
import LogoSvg from "../../atoms/LogoSvg/LogoSvg";
import Title from "../../atoms/Title/Title";

interface Props {
  isModalOpen: boolean;
  handleClose: () => void;
}

const ModalSubscriptionInformations = ({ isModalOpen, handleClose }: Props) => {
  const { t } = useTranslation();
  return (
    <Modal open={isModalOpen} onClose={handleClose}>
      <Box className="ModalInformationSubscription">
        <Box className="modalAlignement" sx={{ padding: "20px" }}>
          <Box className="closingCross">
            <LogoSvg src={CROSS} className="logo" onClick={handleClose} />
          </Box>
          <Box className="modalLogo alignCenter">
            <Avatar>
              <LogoSvg src={INFO} />
            </Avatar>
          </Box>
          <Box className="modalTitle">
            <Title
              variant="h1"
              children={t("subscription:FIRST_PAGE.MODAL_INFORMATION.TITLE")}
            />
          </Box>
          <Box className="modalText">
            <Text
              children={t("subscription:FIRST_PAGE.MODAL_INFORMATION.TEXT")}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalSubscriptionInformations;
