import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "./rtk/_config/hooks";
import { RootState } from "./types/rtk/api-config/types";
import { setAuth } from "./rtk/auth/auth";
import SpinnerLoader from "./components/atoms/SpinnerLoader/SpinnerLoader";
import { setUserFromOrder } from "./rtk/user/user";
import { useLazyGetOrderQuery } from "./rtk/order/order";
import { setSettings } from "./rtk/settings/settings";
import { AppRoutes } from "./routes/AppRoutes";
import { setOrder } from "./rtk/order/order-slice";
import { OrderResponse } from "./types/rtk/order/order";
import { useLazyGetOffersQuery } from "./rtk/offers/offers";


const App = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const [initializing, setInitializing] = useState<boolean>(false);
  const [authenticated, setAuthenticated] = useState<boolean>(false);

  // Authentification information
  const auth_token = searchParams.get("auth");
  const external_id = useAppSelector(
    (state: RootState) => state.auth.external_id
  );
  const order_ref = useAppSelector((state: RootState) => state.order.reference);
  const offers_ref = useAppSelector((state: RootState) => state.offers.validityRef);
  const [getOrderInfo] = useLazyGetOrderQuery();
  const [getOffers] = useLazyGetOffersQuery();

  // Language information
  const lang = searchParams.get("lang");
  const user = useAppSelector((state: RootState) => state.user);
  const settings = useAppSelector((state: RootState) => state.settings);

  useEffect(() => {
    if (auth_token && external_id !== auth_token) {
      const should_go_home = external_id !== undefined;
      dispatch(setAuth({external_id: auth_token}));
      if (should_go_home){navigate(AppRoutes.BASE_ROUTE);}
    }
    else if (!initializing && !authenticated) {
      setInitializing(true);
      if (external_id || order_ref) {
        (async () => {
          let current_order: OrderResponse | undefined = undefined;
          if (order_ref){
            // Try to get info on locally saved order
            const resp = await getOrderInfo({ref: order_ref});
            if (!resp.isError && resp.data && resp.data.auth === external_id
              && ((resp.data.status !== 'new' && resp.data.status !== 'retry')
                  || !resp.data.expired)) {
              current_order = resp.data;
            }
          }
          if (current_order === undefined){
            const resp = await getOrderInfo({ref: undefined})
            if (resp.isError || resp.data === undefined) {
              setInitializing(false);
              navigate(AppRoutes.UNKNOWN);
              return;
            }
            current_order = resp.data;
          }
          if (offers_ref !== current_order.reference){
            const resp = await getOffers({ ref: current_order.reference });
            // This will update the offers store
            if (resp.isError || resp.data === undefined) {
              setInitializing(false);
              navigate(AppRoutes.UNKNOWN);
              return;
            }
          }
          await dispatch(setOrder(current_order));
          await dispatch(setUserFromOrder(current_order));
          setAuthenticated(true);
          setInitializing(false);
        })();
      } else {
        navigate(AppRoutes.UNKNOWN);
      }
    }
  }, [
    auth_token, external_id, order_ref,
    initializing, authenticated, dispatch,
    getOffers, getOrderInfo, navigate,
    offers_ref
  ]);

  useEffect(() => {
    if (lang) {
      dispatch(setSettings({ language: lang }));
    }
  }, [i18n, dispatch, settings, lang]);

  useEffect(() => {
    const final_lang = settings.language || user.language;
    if (final_lang && final_lang !== i18n.language) {
      i18n.changeLanguage(final_lang);
    }
  }, [user, settings, i18n, dispatch]);

  return (
    <Box className="App">{authenticated ? <Outlet /> : <SpinnerLoader size={100}/>}</Box>
  );
};

export default App;
