import { NewHipayMeanBody } from "../../types/rtk/payment/mean";
import { apiSlice } from "../_api/api-slice";

const paymentEndpoint = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    createHipayCard: build.query<any, {body: NewHipayMeanBody}>({
      query: ({body}) => {
        console.log(body);
        return {
        url: `/payment/mean`,
        method: "post",
        body
      }},
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyCreateHipayCardQuery,
} = paymentEndpoint;

