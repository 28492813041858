import { Box } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Title from "../../atoms/Title/Title";
import Header from "../../templates/Header/Header";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";
import { useAppDispatch, useAppSelector } from "../../../rtk/_config/hooks";
import { RootState } from "../../../types/rtk/api-config/types";
import { AppRoutes } from "../../../routes/AppRoutes";
import { setOrder } from "../../../rtk/order/order-slice";
import { useLazyGetOrderQuery } from "../../../rtk/order/order";


const Home = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const order = useAppSelector((state: RootState) => state.order);
  const [getOrderInfo] = useLazyGetOrderQuery();

  useEffect(() => {
    if (order.invalid){
      (async () => {
        const resp = await getOrderInfo({ref: order.reference!}, false);
        if (resp.isError || resp.data === undefined)
          navigate(AppRoutes.UNKNOWN);
        else
          await dispatch(setOrder(resp.data));
      })()
    }
    
    if (order.status){
      if (order.status === "success")
        navigate(AppRoutes.SUCCESS);
      else if (order.status === "pending")
        navigate(AppRoutes.PENDING);
      else if (order.status === "error")
        navigate(AppRoutes.FAIL);
      else if (order.offer_id === null)
        navigate(AppRoutes.SUBSCRIPTIONS);
      else if (order.options === null)
        navigate(AppRoutes.OPTIONS);
      else if (["new", "retry"].includes(order.status))
        navigate(AppRoutes.PAYMENT);
    }
  }, [order, navigate, getOrderInfo, dispatch]);

  return (
    <>
      <Header headerStep={1} title={t("subscription:HOME_PAGE.TITLE")} />
      <Box className="Pending">
        <Box className="content">
          <SpinnerLoader size={100} style={{"height": "auto"}} />
          <Title
            variant="h1"
            children={t("subscription:HOME_PAGE.TITLE")}
          />
        </Box>
      </Box>
    </>
  );
};

export default Home;
