import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import PaymentCard from "../PaymentCard/PaymentCard";
import Title from "../../atoms/Title/Title";
import { useAppDispatch, useAppSelector } from "../../../rtk/_config/hooks";
import { RootState } from "../../../types/rtk/api-config/types";
import { setBasket } from "../../../rtk/basket/basket";
// import { RootState } from "../../../types/rtk/api-config/types";
// import { useAppSelector } from "../../../rtk/_config/hooks";

interface Props {
  handleOpenGiftCard: () => void;
  handleHipay: (response: any) => Promise<void>;
}

const PaymentList = ({ handleOpenGiftCard, handleHipay }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const basket = useAppSelector((state: RootState) => state.basket);
  const order = useAppSelector((state: RootState) => state.order);
  const activeMode = basket.paymentMethodSelected;


  const changeSelectedPaymentHandle = (method: string) => {
    if (method !== 'card'
      && method !== 'bank'
      && method !== undefined)
      return ;
    dispatch(setBasket({...basket, paymentMethodSelected: method, isPaymentInformationsOk: method === 'bank' }));
  };

  return (
    <Box className="PaymentList">
      <PaymentCard
        title={t("subscription:THIRD_PAGE.PAYMENT_CHOICE.CARD")}
        isActive={activeMode === 'card'}
        setIsActive={changeSelectedPaymentHandle}
        mode='card'
        handleHipay={handleHipay}
      />
      <PaymentCard
        title={t("subscription:THIRD_PAGE.PAYMENT_CHOICE.BANK")}
        isActive={activeMode === 'bank'}
        setIsActive={changeSelectedPaymentHandle}
        mode='bank'
      />
      {(order.giftcard === null) ?
      <Box className="promoCode" onClick={handleOpenGiftCard}>
        <Box className="promoCodeContainer">
          <Title
            variant="h1"
            children={t("subscription:THIRD_PAGE.MODAL.TITLE")}
          />
        </Box>
      </Box> : null}
    </Box>
  );
};

export default PaymentList;
