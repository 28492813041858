import { Box, Button } from "@mui/material";
import { CSSProperties, ReactNode } from "react";

interface Props {
  text?: string;
  children?: ReactNode;
  style?: CSSProperties;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  className?: string;
  id?: string;
  type?: "button" | "reset" | "submit" | undefined;
  form?: string;
  disabled?: boolean;
}

const StyledButton = ({
  text,
  children,
  style,
  onClick,
  className,
  id,
  type,
  form,
  disabled,
}: Props) => {
  return (
    <Box className={`StyledButton ${className ? className : ""}`} sx={style}>
      <Button
        variant="contained"
        onClick={onClick}
        id={id}
        type={type}
        form={form}
        disabled={disabled}
      >
        {children || text}
      </Button>
    </Box>
  );
};

export default StyledButton;
