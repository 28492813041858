import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OffersState } from "../../types/rtk/subscription/subscription";
import { ApiOffer } from "./offers";


const initialState: OffersState = {
    validityRef: null,
    validUntil: null,
    subscriptionsData: [],
    optionsData: [],
};

function updateState(state: OffersState, payload: OffersState) {
    state.validityRef = payload.validityRef;
    state.validUntil = payload.validUntil;
    state.subscriptionsData = payload.subscriptionsData;
    state.optionsData = payload.optionsData;
}

const OffersSlice = createSlice({
  name: "offers",
  initialState: initialState,
  reducers: {
    setOffers: (state, action: PayloadAction<OffersState>) => {
        updateState(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(ApiOffer.endpoints.getOffers.matchFulfilled, (state, action) => {
        updateState(state, action.payload);
    });
  },
});
export const { setOffers } = OffersSlice.actions;
const OffersReducer = OffersSlice.reducer;
export default OffersReducer;
