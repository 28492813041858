import { Subscription, OffersState } from "../../types/rtk/subscription/subscription";
import { apiSlice } from "../_api/api-slice";

const transformResponse = (response: Subscription) => {
  const subscription = {
    id: response.id,
    type: response.type,
    code: response.code,
    period: response.period,
    price: {
      id: response.price.id,
      currency: response.price.currency,
      amount: response.price.amount,
      savings: response.price.savings ? {
        type: response.price.savings.type,
        reference: response.price.savings.reference,
        amount: response.price.savings.amount,
        ratio: 0,
      } : null,
    },
  };

  const arraySubscription = response.variants;

  arraySubscription.push(subscription);

  return {
    validityRef: response.validityRef,
    validUntil: response.validUntil,
    subscriptionsData: arraySubscription,
    optionsData: response.options,
  };
};

const offers = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getOffers: build.query<OffersState,{ref?: string}>({
      query: ({ref}) => ({
        url: "/public/offers",
        params: ref ? {order_reference: ref} : undefined,
        method: "get",
      }),
      transformResponse: transformResponse,
    }),
  }),
  overrideExisting: false,
});

export const ApiOffer = offers;

export const { useGetOffersQuery, useLazyGetOffersQuery } = offers;
