import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";

import "./styles/main.scss";
import i18n from "./i18n/i18n";
import store from "./rtk/_config/store";
import App from "./App";
import { AppRoutes } from "./routes/AppRoutes";
import Home from "./components/pages/Home/Home";
import ErrorPage from "./components/pages/ErrorPage/ErrorPage";
import Subscriptions from "./components/pages/Subscriptions/Subscriptions";
import Options from "./components/pages/Options/Options";
import Payment from "./components/pages/Payment/Payment";
import Success from "./components/pages/Success/Success";
import Fail from "./components/pages/Fail/Fail";
import Unknown from "./components/pages/Unknown/Unknown";
import Pending from "./components/pages/Pending/Pending";
import Cancel from "./components/pages/Cancel/Cancel";

const router = createBrowserRouter([
  {
    path: AppRoutes.BASE_ROUTE,
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: AppRoutes.SUBSCRIPTIONS,
        element: <Subscriptions />,
        loader: () => ({headerStep: 1, branch: "payement"}),
      },
      {
        path: AppRoutes.OPTIONS,
        element: <Options />,
        loader: () => ({headerStep: 2, branch: "payement"}),
      },
      {
        path: AppRoutes.PAYMENT,
        element: <Payment />,
        loader: () => ({headerStep: 3, branch: "payement"}),
      },
      {
        path: AppRoutes.SUCCESS,
        element: <Success />,
        loader: () => ({headerStep: 0, branch: "payement"}),
      },
      {
        path: AppRoutes.FAIL,
        element: <Fail />,
      },
      {
        path: AppRoutes.PENDING,
        element: <Pending />,
      },
    ],
  },
  {
    path: AppRoutes.UNKNOWN,
    element: <Unknown />,
    errorElement: <ErrorPage />,
  },
  {
    path: AppRoutes.CANCEL,
    element: <Cancel />,
    errorElement: <ErrorPage />,
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <RouterProvider router={router} />
      </I18nextProvider>
    </Provider>
  </React.StrictMode>
);
