import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserState } from "../../types/rtk/user/user";
import { OrderResponse } from "../../types/rtk/order/order";

const initialState: UserState = {
  language: "",
  country: "",
};

const UserSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      state.language = action.payload.language;
      state.country = action.payload.country;
    },
    setUserFromOrder: (state, action: PayloadAction<OrderResponse>) => {
      state.language = state.language || action.payload.language;
      state.country = state.country || action.payload.country;
    },
  },
});

export const { setUser, setUserFromOrder } = UserSlice.actions;
const UserReducer = UserSlice.reducer;
export default UserReducer;
