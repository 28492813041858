import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { getYearByMonth } from "../../../helpers/getYearByMonth";
import Text from "../../atoms/Text/Text";
import Title from "../../atoms/Title/Title";
import { RootState } from "../../../types/rtk/api-config/types";
import { useAppSelector } from "../../../rtk/_config/hooks";

const Basket = () => {
  const { t } = useTranslation();
  const basket = useAppSelector((state: RootState) => state.basket);
  const [price, setPrice] = useState(0);
  const [reducedPrice, setReducedPrice] = useState(0);
  const [isOpen, setIsOpen] = useState(true);

  const base_sub_price = basket.subscription?.price.amount ?? 0.0;
  const sub_price = Math.round(((
        base_sub_price * (1.0 - (basket.giftcard_ratio ?? 0.0))
    ) + Number.EPSILON) * 100) / 100;
  const giftcard_reduction = Math.round((basket.giftcard_ratio || 0) * 100);
  const promocode_amount = Math.round(((basket.promocode_ratio ?? 0.0) * base_sub_price + Number.EPSILON) * 100) / 100;

  const sub_name = (getYearByMonth(basket.subscription?.period ?? 0) >= 1) ? t(
      "subscription:FIRST_PAGE.SUBSCRIPTION_CHOICE.SUBSCRIPTION_TITLE",
      {count: getYearByMonth(basket.subscription?.period ?? 0)}
  ) : t("subscription:FIRST_PAGE.SUBSCRIPTION_CHOICE.SUBSCRIPTION_TITLE_MONTHLY");

  useEffect(() => {
    if (basket) {
      let sum = sub_price;
      basket.options?.forEach((opt) => (sum += opt.code !== "premium_pack" ? opt.price.amount : 0.0));
      setPrice(parseFloat(sum.toFixed(2)));
    }
  }, [basket, sub_price]);
  
  useEffect(() => {
    if (basket) {
      if (basket.promocode_ratio){
        setReducedPrice(parseFloat((price - (basket.promocode_ratio * base_sub_price)).toFixed(2)));
      } else {
        setReducedPrice(price);
      }
    }
  }, [price, base_sub_price, basket]);

  return (
    <Box className="Basket">
      <Box className="offerChoosen">
        <Accordion
          className="Accordion"
          expanded={isOpen}
          onClick={() => setIsOpen(!isOpen)}
        >
          <AccordionSummary
            id="panel-header"
            aria-controls="panel-content"
            expandIcon={<ExpandMoreIcon />}
          />
          {/* {!isOpen ? "Afficher" : "Cacher"} le panier
          </AccordionSummary> */}
          <AccordionDetails>
            <Box className="subscription">
              <Box className="text">
                {basket.giftcard ? (
                  <>
                    <Text>{t("subscription:FIRST_PAGE.MODAL_GIFT_CARD.INPUT_LABEL")} : {basket.giftcard}</Text>
                    <Box className="subTitleBox">
                      <Text className="subTitle">{sub_name} {
                      (giftcard_reduction < 100)
                        ? t("subscription:FOR_MINUS_X_PERCENT", {percent: giftcard_reduction})
                        : t("subscription:FREE")}</Text>
                    </Box>
                  </>
                  ) : <Text children={sub_name} />
                }
              </Box>
              <Box className="price text">
                <Text children={(sub_price > 0.0) ? `${sub_price}€` : t("subscription:FREE")} />
                {basket.giftcard &&
                  <Box className="subTitleBox">
                    <Text className="old-price subTitle" children={`${basket.subscription?.price.amount}€`} />
                  </Box>
                }
              </Box>
            </Box>
            
            <Box className="options">
              {basket &&
                basket.options?.map((opt, i) => (
                  <Box className="optionAlignment" key={i}>
                    <Box className="text">
                      <Text
                        className="title"
                        children={t(
                          `subscription:SECOND_PAGE.OPTION_CHOICE.TITLE.${opt.code}`
                        )}
                      />
                      <Box className="subTitleBox">
                        <Text
                          className="subTitle"
                          children={
                            t(
                              `subscription:SECOND_PAGE.BASKET.TEXT.${opt.code}`
                            ) + " "
                          }
                        />
                        {opt.code === "premium_pack" && ( //TODO PRICE
                          <Text
                            className="title"
                            children={
                              opt.price.amount +
                              "€ / " +
                              t("subscription:MONTH")
                            }
                          />
                        )}
                      </Box>
                    </Box>
                    <Box className="price">
                      <Text
                        children={
                          opt.code === "premium_pack"
                            ? t("subscription:FREE")
                            : opt.price.amount + "€"
                        }
                      />
                    </Box>
                  </Box>
                ))}
            </Box>

            {basket.promo ? <Box className="promo">
              <Box className="text">
                <Text>Promo code : {basket.promo}</Text>
              </Box>
              <Box className="price text">
                <Text children={`-${promocode_amount}€`} />
              </Box>
            </Box> : null}
            
          </AccordionDetails>
        </Accordion>
      </Box>

      <Box className="totalPrice">
        <Box className="text">
          <Title
            variant="h1"
            children={t("subscription:SECOND_PAGE.BASKET.TOTAL")}
          />
        </Box>
        <Box className="price">
          <Title variant="h1" children={(reducedPrice > 0.0) ?  `${reducedPrice}€` : t("subscription:FREE")} />
        </Box>
      </Box>
    </Box>
  );
};

export default Basket;
