import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

// import HELP from "../../../assets/images/HELP.svg";
// import LogoSvg from "../../atoms/LogoSvg/LogoSvg";
import Title from "../../atoms/Title/Title";
import Text from "../../atoms/Text/Text";

interface Props {
  headerStep: number;
  title?: string;
  goBack?: () => void;
}

const Header = ({ headerStep, title, goBack }: Props) => {
  const { t } = useTranslation();

  return (
    <Box className="Header">
      <Box className="content">
        <Grid container spacing={2}>
          <Grid xs={3} className="alignStart">
            {goBack && (
              <ArrowBackIosNewIcon
                className="Logos"
                onClick={goBack}
              />
            )}
          </Grid>
          <Grid xs={6} className="alignCenter">
            {headerStep !== 4 && (
              <Text
                className="test"
                children={`${t("subscription:HEADER.STEP")} ${headerStep}/3`}
              />
            )}
            {title && <Title variant="h3" children={title} />}
          </Grid>
          <Grid xs={3} className="alignEnd" />
          {/* <Grid xs={3} className="alignEnd">
            <LogoSvg className="Logos" src={HELP} />
          </Grid> */}
        </Grid>
      </Box>
    </Box>
  );
};

export default Header;
