import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import { SubscriptionOptions } from "../../../types/rtk/subscription/subscription";
import LogoSvg from "../../atoms/LogoSvg/LogoSvg";
import ActiveButton from "../../../assets/images/RADIO-BUTTON-ACTIVE.svg";
import InnactiveButton from "../../../assets/images/RADIO-BUTTON-INNACTIVE.svg";
import Title from "../../atoms/Title/Title";
import Chip from "../../atoms/Chip/Chip";
import Text from "../../atoms/Text/Text";
import { useAppDispatch, useAppSelector } from "../../../rtk/_config/hooks";
import { setBasket } from "../../../rtk/basket/basket";
import { RootState } from "../../../types/rtk/api-config/types";

interface Props {
  data: SubscriptionOptions;
  index: number;
  isActive: boolean;
}

const OptionCard = ({ data, index, isActive }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const basket = useAppSelector((state: RootState) => state.basket);

  const handleSetActive = () => {
    const tmpArray = basket.options?.slice();

    if (tmpArray) {
      if (isActive) {
        const index = tmpArray.indexOf(data);
        tmpArray.splice(index, 1);
        dispatch(setBasket({ ...basket, options: tmpArray }));
      } else {
        tmpArray.push(data);
        dispatch(setBasket({ ...basket, options: tmpArray }));
      }
    }
  };

  return (
    <Box
      className={`OptionCard ${isActive && "isOptionCardActive"}`}
      onClick={handleSetActive}
    >
      <Box className="offer">
        <Box className="optionChip">
          <LogoSvg src={isActive ? ActiveButton : InnactiveButton} />
        </Box>
        <Box className="optionTitle">
          {index === 0 && (
            <Chip
              text={t("subscription:SECOND_PAGE.OPTION_CHOICE.CHIP")}
              isActive
            />
          )}
          <Title
            variant="h1"
            children={t(
              `subscription:SECOND_PAGE.OPTION_CHOICE.TITLE.${data.code}`
            )}
          />
        </Box>
        {data && (
          <Box className="optionPrice">
            {data && (
              <>
                <Box className="prices">
                  <Title variant="h1" className="picesColor">
                    {`${(data.price.amount / data.period).toFixed(2)}€ `}
                  </Title>
                  <Title variant="h1">{` / ${t("subscription:MONTH")}`}</Title>
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>
      <Box className="text">
        <Text
          children={t(
            `subscription:SECOND_PAGE.OPTION_CHOICE.TEXT.${data.code}`
          )}
        />
      </Box>
    </Box>
  );
};

export default OptionCard;
