import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import LogoSvg from "../../atoms/LogoSvg/LogoSvg";
import SUCCESS from "../../../assets/images/SUCCESS.svg";
import Text from "../../atoms/Text/Text";
import Title from "../../atoms/Title/Title";
import Footer from "../../templates/Footer/Footer";
import Header from "../../templates/Header/Header";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import { setOrderRef } from "../../../rtk/order/order-slice";
import { useDispatch } from "react-redux";
import { persistedStore } from "../../../rtk/_config/store";

const Success = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const ReturnToTheApp = async () => {
    await dispatch(setOrderRef({reference: null, expire_at: null, expired: true, status: "success"}));
    await persistedStore.flush();
    window.location.href = "weenect://app/home";
  };

  return (
    <>
      <Header headerStep={4} title={t("subscription:SUCCESS_PAGE.TITLE")} />
      <Box className="Success">
        <Box className="content">
          <LogoSvg className="Logos" src={SUCCESS} />
          <Title
            variant="h1"
            children={t("subscription:SUCCESS_PAGE.TITLE") + " !"}
          />
          <Text children={t("subscription:SUCCESS_PAGE.TEXT")} />
        </Box>
      </Box>
      <Footer headerStep={1}>
        <StyledButton
          children={t("subscription:FIRST_PAGE.BUTTON")}
          onClick={() => ReturnToTheApp()}
          />
      </Footer>
    </>
  );
};

export default Success;
