import { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { configHipay, hipayInstance } from "../../../assets/constants/hipay";
import { setBasket } from "../../../rtk/basket/basket";
import { RootState } from "../../../types/rtk/api-config/types";
import { useAppDispatch, useAppSelector } from "../../../rtk/_config/hooks";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";


interface Props {
  handleHipay: (response: any) => Promise<void>;
}

const PaymentForm = ({ handleHipay }: Props) => {
  const dispatch = useAppDispatch();
  const basket = useAppSelector((state: RootState) => state.basket);
  const [isLoading, setIsLoading] = useState(false);
  const instance = useRef(null);

  useEffect(() => {
    if (instance.current === null) {
        instance.current = hipayInstance.create("card", configHipay);
    }
    const cardForm = document.getElementById("hipay-form");

    const handleSubmit = async (event: SubmitEvent) => {
      setIsLoading(true);
      event.preventDefault();
      try {
        // @ts-ignore
        const response = await instance.current!.getPaymentData();
        console.log("response", response);
        setIsLoading(false);
        // navigate(AppRoutes.SUCCESS);
        handleHipay(response);
      } catch (error) {
        if (error === "Error: TOKENIZATION_IN_PROGRESS") {
          console.log("Please wait..");
        } else {
          console.log(error);
          setIsLoading(false);
        }
      }
    };

    // @ts-ignore
    instance.current!.on("change", (event: any) => {
      document.getElementById("hipay-error-message")!.innerHTML = event.error;
      dispatch(setBasket({ ...basket, isPaymentInformationsOk: event.valid }));
    });

    if (cardForm) {
      cardForm.addEventListener("submit", handleSubmit);
    }

    return () => {
      if (cardForm) {
        cardForm.removeEventListener("submit", handleSubmit);
      }
      // @ts-ignore
      instance.current!.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="PaymentForm" component={"form"} id="hipay-form">
      <Box className="inputAlignment">
        <Typography component={"label"}>Numéro de carte</Typography>
        <Box className="hostedfield" id="hipay-card-number" />
      </Box>
      <Box className="formAlignement">
        <Box className="inputAlignment">
          <Typography component={"label"}>Date d’expiration</Typography>
          <Box className="hostedfield" id="hipay-expiry-date" />
        </Box>
        <Box className="inputAlignment">
          <Typography component={"label"}>Code d’expiration</Typography>
          <Box className="hostedfield" id="hipay-cvc" />
        </Box>
      </Box>
      <Box className="inputAlignment">
        <Typography component={"label"}>Nom sur la carte</Typography>
        <Box className="hostedfield" id="hipay-card-holder" />
      </Box>
      {/* <Button type="submit" id="hipay-submit-button" disabled>
        PAY
      </Button> */}
      <Box id="hipay-error-message" />
      {isLoading && <SpinnerLoader />}
    </Box>
  );
};

export default PaymentForm;
