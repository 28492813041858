import { Box } from "@mui/material";

import { SubscriptionOptions } from "../../../types/rtk/subscription/subscription";
import OptionCard from "../OptionCard/OptionCard";
import { RootState } from "../../../types/rtk/api-config/types";
import { useAppSelector } from "../../../rtk/_config/hooks";

interface Props {
  datas: SubscriptionOptions[];
}

const OptionList = ({ datas }: Props) => {
  const basket = useAppSelector((state: RootState) => state.basket);

  return (
    <Box className="OptionList">
      {datas.map((obj, i) => (
        <OptionCard
          key={i}
          data={obj}
          index={i}
          isActive={
            basket.options
              ?.map((el) => JSON.stringify(el))
              .includes(JSON.stringify(obj)) ?? false
          }
        />
      ))}
    </Box>
  );
};

export default OptionList;
