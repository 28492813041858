import { useEffect } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import OptionChoice from "../../organisms/OptionChoice/OptionChoice";
import { useAppDispatch, useAppSelector } from "../../../rtk/_config/hooks";
import { setBasket } from "../../../rtk/basket/basket";
import { RootState } from "../../../types/rtk/api-config/types";
import Header from "../../templates/Header/Header";
import Footer from "../../templates/Footer/Footer";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import { AppRoutes } from "../../../routes/AppRoutes";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";
import { OrderBody } from "../../../types/rtk/order/order";
import { setOrder } from "../../../rtk/order/order-slice";
import { useLazyPostOrderQuery } from "../../../rtk/order/order";

const Options = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const basket = useAppSelector((state: RootState) => state.basket);
  const order_ref = useAppSelector((state: RootState) => state.order.reference);
  const order = useAppSelector((state: RootState) => state.order);
  const data = useAppSelector((state: RootState) => state.offers);
  const [sendOrderUpdate, { isLoading }] = useLazyPostOrderQuery();

  useEffect(() => {
    if (basket.subscription === null) navigate(AppRoutes.SUBSCRIPTIONS);
    else if (data) {
      if (basket.options === null) {
        dispatch(setBasket({ ...basket, options: data.optionsData }));
      }
    }
  }, [dispatch, basket, data, navigate]);


  const change_step_navigation = async (forward: boolean = true) => {
    const selected_options = basket.options ? basket.options.map((elm) => elm.id) : [];
    if ((selected_options.length !== order.options?.length)
      || (order.options !== null && order.options.find((elm) => !selected_options.includes(elm)))
    ) {
      let body: OrderBody = {
        offer_id: order.offer_id,
        options: selected_options,
        giftcard: order.giftcard,
        promo: order.promo,
      };
      const { data, error } = await sendOrderUpdate({ref: order_ref!, body: body});
      if (error){ navigate("/error"); return; }
      if (data){ await dispatch(setOrder(data)); }
    }
    forward ? navigate(AppRoutes.PAYMENT) : navigate(AppRoutes.SUBSCRIPTIONS);
  }


  return (
    <>
      <Header
        headerStep={2}
        title={t("subscription:HEADER.TITLE.SECOND_PAGE")}
        goBack={() => change_step_navigation(false)}
      />
      {(data) ? <Box className="Option">
        <OptionChoice datas={data.optionsData} />
      </Box> : <SpinnerLoader />}
      <Footer headerStep={2}>
        <StyledButton
          children={isLoading ? <SpinnerLoader/> : t("subscription:SECONDE_PAGE.BUTTON")}
          onClick={() => change_step_navigation()}
          disabled={isLoading || !order_ref}
        />
      </Footer>
    </>
  );
};

export default Options;
