import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SubscriptionSilce } from "../../types/rtk/subscription/subscription";

const initialState: SubscriptionSilce = {
  validityRef: null,
};

const SubscriptionSlice = createSlice({
  name: "subscription",
  initialState: initialState,
  reducers: {
    setSubscription: (state, action: PayloadAction<SubscriptionSilce>) => {
      state.validityRef = action.payload.validityRef;
    },
  },
});
export const { setSubscription } = SubscriptionSlice.actions;
const SubscriptionReducer = SubscriptionSlice.reducer;
export default SubscriptionReducer;
