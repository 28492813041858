import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import Title from "../../atoms/Title/Title";
import Text from "../../atoms/Text/Text";
// import SubscriptionIncludes from "../../molecules/SubscriptionIncludes/SubscriptionIncludes";

const SubscriptionCGV = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const getCGVByLang = () => {
    switch (i18n.language.toLowerCase().trim()) {
      case "fr":
        return "https://www.weenect.com/fr/fr/mentions-legales/";
      case "en":
        return "https://www.weenect.com/uk/en/legal-information/";
      case "it":
        return "https://www.weenect.com/it/it/note-legali/";
      case "es":
        return "https://www.weenect.com/es/es/avisos-legales/";
      case "nl":
        return "https://www.weenect.com/nl/nl/juridische-kennisgeving/";
      case "de":
        return "https://www.weenect.com/de/de/impressum/";
      default:
        return "https://www.weenect.com/uk/en/legal-information/";
    }
  };

  return (
    <Box className="SubscriptionCGV">
      {/* <Title
        variant="h1"
        className="subscriptionSubtitle"
        children={t("subscription:FIRST_PAGE.SUBSCRIPTION_CGV.TITLE")}
      /> */}

      {/* <SubscriptionIncludes /> */}

      <Text
        className="textCGV"
        children={t("subscription:FIRST_PAGE.SUBSCRIPTION_CGV.FIRST_TEXT")}
      />
      <Text className="textCGV">
        {t("subscription:FIRST_PAGE.SUBSCRIPTION_CGV.SECOND_TEXT") + " "}
        <Link to={getCGVByLang()} className="link">
          {t("subscription:FIRST_PAGE.SUBSCRIPTION_CGV.SECOND_TEXT_LINK")}
        </Link>
      </Text>
    </Box>
  );
};

export default SubscriptionCGV;
