import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist/es/constants";

import { apiSlice } from "../_api/api-slice";
import ThemeReducer from "../theme/theme";
import AuthReducer from "../auth/auth";
import BasketReducer from "../basket/basket";
import UserReducer from "../user/user";
import SettingsReducer from "../settings/settings";
import OrderReducer from "../order/order-slice";
import OffersReducer from "../offers/offers-slice";

const combineSlices = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  theme: ThemeReducer,
  auth: AuthReducer,
  basket: BasketReducer,
  user: UserReducer,
  settings: SettingsReducer,
  order: OrderReducer,
  offers: OffersReducer,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["api", "user", "basket"],
};

const persistedReducer = persistReducer(persistConfig, combineSlices);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(apiSlice.middleware),
});

export const persistedStore = persistStore(store);
export default store;
