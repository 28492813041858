import { BasketState } from "../../types/rtk/basket/basket";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrderBody, OrderResponse } from "../../types/rtk/order/order";
import { OrderSlice } from "../order/order-slice";
import { OffersState } from "../../types/rtk/subscription/subscription";

const initialState: BasketState = {
  subscription: null,
  giftcard: null,
  promo: null,
  options: null,
  giftcard_ratio: null,
  promocode_ratio: null,
  paymentMethodSelected: undefined,
  isPaymentInformationsOk: false,
};

function updatFromOrder(state: BasketState, order: OrderBody | OrderResponse, offers: OffersState) {
  state.giftcard = order.giftcard;
  state.promo = order.promo;
  if (order.offer_id && offers.subscriptionsData.length) {
    state.subscription = offers.subscriptionsData.find(
      (elm) => elm.id === order.offer_id
    ) || null;
  } else { state.subscription = null; }
  if (order.options && offers.optionsData.length){
    state.options = offers.optionsData.filter(
      (elm) => order.options!.includes(elm.id)
    );
  } else { state.options = null; }
}

const BasketSlice = createSlice({
  name: "basket",
  initialState: initialState,
  reducers: {
    setBasket: (state, action: PayloadAction<BasketState>) => {
      state.subscription = action.payload.subscription;
      state.options = action.payload.options;
      state.isPaymentInformationsOk = action.payload.isPaymentInformationsOk;
      state.paymentMethodSelected = action.payload.paymentMethodSelected;
      state.giftcard = action.payload.giftcard;
      state.promo = action.payload.promo;
      state.giftcard_ratio = action.payload.giftcard_ratio;
      state.promocode_ratio = action.payload.promocode_ratio;
    },
    basketFromOrder: (state, action: PayloadAction<OrderResponse>) => {
      state.giftcard = action.payload.giftcard;
      state.promo = action.payload.promo;
      state.giftcard_ratio = action.payload.giftcard_ratio;
      state.promocode_ratio = action.payload.promocode_ratio;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(OrderSlice.actions.setOrder, (state, action) => {
      updatFromOrder(state, action.payload.order, action.payload.offers);
      state.giftcard_ratio = action.payload.order.giftcard_ratio;
      state.promocode_ratio = action.payload.order.promocode_ratio;
    }).addCase(OrderSlice.actions.setOrderBody, (state, action) => {
      updatFromOrder(state, action.payload.order, action.payload.offers);
    })
  }
});

export const { setBasket } = BasketSlice.actions;
const BasketReducer = BasketSlice.reducer;
export default BasketReducer;
