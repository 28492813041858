import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import Title from "../../atoms/Title/Title";
import OptionList from "../../molecules/OptionList/OptionList";
import { SubscriptionOptions } from "../../../types/rtk/subscription/subscription";
import Text from "../../atoms/Text/Text";

interface Props {
  datas: SubscriptionOptions[];
}

const OptionChoice = ({ datas }: Props) => {
  const { t } = useTranslation();

  return (
    <Box className="OptionChoice">
      <Box className="optionSubtitleContainer">
        <Title
          variant="h1"
          className="optionSubtitle"
          children={t("subscription:SECOND_PAGE.TITLE")}
        />
        <Text
          className="optionTextSubtitle"
          children={t("subscription:SECOND_PAGE.SUBTITLE")}
        />
      </Box>

      <OptionList datas={datas} />
    </Box>
  );
};

export default OptionChoice;
