import {
  createApi,
  fetchBaseQuery,
  BaseQueryApi,
} from "@reduxjs/toolkit/query/react";
// import { RootState } from "../../types/rtk/api-config/types";
import { config as appConfig } from "../../config";
import { RootState } from "../../types/rtk/api-config/types";

const query = fetchBaseQuery({
  baseUrl: appConfig.REACT_APP_APIV4_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.external_id;

    if (token) {
      // headers.set("authorization", `JWT ${token}`);
      headers.set("authorization", `${token}`);
    }

    return headers;
  },
});

const baseQuery = async (args: any, api: BaseQueryApi, extraOptions: {}) => {
  let result = await query(args, api, extraOptions);
  if (result.error) {
    console.log("error");
    // window.location.href = "/error";
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQuery,
  endpoints: () => ({}),
  tagTypes: [],
});
