import { useEffect } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import SubscriptionChoice from "../../organisms/SubscriptionChoice/SubscriptionChoice";
import SubscriptionCGV from "../../organisms/SubscriptionCGV/SubscriptionCGV";
// import { Subscription } from "../../../types/rtk/subscription/subscription";
// import { mockedData } from "../../../helpers/mockedDatas";
import { setSubscription } from "../../../rtk/subscription/subscription-slice";
import { useAppDispatch, useAppSelector } from "../../../rtk/_config/hooks";
import { setBasket } from "../../../rtk/basket/basket";
import { RootState } from "../../../types/rtk/api-config/types";
import Footer from "../../templates/Footer/Footer";
import Header from "../../templates/Header/Header";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import { AppRoutes } from "../../../routes/AppRoutes";
import { useLazyGetOffersQuery } from "../../../rtk/offers/offers";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";
import { useLazyPostOrderQuery } from "../../../rtk/order/order";
import { OrderBody } from "../../../types/rtk/order/order";
import { setOrder } from "../../../rtk/order/order-slice";

// const transformResponse = (response: Subscription) => {
//   const subscription = {
//     id: response.id,
//     type: response.type,
//     code: response.code,
//     period: response.period,
//     price: {
//       id: response.price.id,
//       currency: response.price.currency,
//       amount: response.price.amount,
//       savings: response.price.savings ? {
//         type: response.price.savings.type,
//         reference: response.price.savings.reference,
//         amount: response.price.savings.amount,
//         ratio: 0,
//       } : null,
//     },
//   };

//   const arraySubscription = response.variants;

//   arraySubscription.push(subscription);

//   return {
//     validityRef: response.validityRef,
//     validUntil: response.validUntil,
//     subscriptionsData: arraySubscription,
//   };
// };

// const datas = transformResponse(mockedData);

const Subscriptions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const basket = useAppSelector((state: RootState) => state.basket);
  const order_ref = useAppSelector((state: RootState) => state.order.reference);
  const order = useAppSelector((state: RootState) => state.order);
  const data = useAppSelector((state: RootState) => state.offers);
  // const { data } = useGetOffersQuery({ref: order_ref!}, {skip: !order_ref});
  const [sendOrderUpdate, { isLoading }] = useLazyPostOrderQuery();
  const [getOffers, { isLoading: offersLoading }] = useLazyGetOffersQuery();

  useEffect(() => {
    if (data.validityRef) {
      dispatch(setSubscription({ validityRef: data.validityRef }));
      if (basket.subscription === undefined) {
        dispatch(
          setBasket({ ...basket, subscription: data.subscriptionsData[0] })
        );
      }
    }
  }, [dispatch, basket, data]);

  useEffect(() => {
    if (order_ref && (data.validityRef === null || data.validityRef === undefined)) {
      getOffers({ref: order_ref});
    }
  }, [data, order_ref, getOffers]);


  const next_step_navigation = async () => {
    if (basket.subscription?.id !== order.offer_id || basket.giftcard !== order.giftcard){
      let body: OrderBody = {
        offer_id: order.offer_id, options: order.options,
        giftcard: basket.giftcard, promo: order.promo,
      };
      body.offer_id = basket.subscription?.id ?? null;
      const { data, isError } = await sendOrderUpdate({ref: order_ref!, body: body});
      if (isError || !data){
        navigate("/error");
        return;
      }
      await dispatch(setOrder(data!));
    }
    navigate(AppRoutes.OPTIONS)
  }

  return (
    <>
      <Header
        headerStep={1}
        title={t("subscription:HEADER.TITLE.FIRST_PAGE")}
      />
      <Box className="Subscription">
        {offersLoading ?  <SpinnerLoader/> : <SubscriptionChoice datas={data} />}
        <SubscriptionCGV />
      </Box>
      <Footer headerStep={1}>
        <StyledButton
          children={isLoading ? <SpinnerLoader/> : t("subscription:FIRST_PAGE.BUTTON")}
          onClick={() => next_step_navigation()}
          disabled={isLoading || !order_ref || (basket.subscription === null)}
        />
      </Footer>
    </>
  );
};

export default Subscriptions;
