import { useState } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import Title from "../../atoms/Title/Title";
import LogoSvg from "../../atoms/LogoSvg/LogoSvg";
import INFO from "../../../assets/images/INFO.svg";
import SubscriptionList from "../../molecules/SubscriptionList/SubscriptionList";
import ModalSubscriptionInformations from "../../molecules/ModalSubscriptionInformations/ModalSubscriptionInformations";
import ModalPromotionalCode from "../../molecules/ModalSubscriptionGiftCard/ModalSubscriptionGiftCard";
import { RootState } from "../../../types/rtk/api-config/types";
import { useAppDispatch, useAppSelector } from "../../../rtk/_config/hooks";
import { OffersState } from "../../../types/rtk/subscription/subscription";
import { useLazyPostOrderQuery } from "../../../rtk/order/order";
import { OrderBody } from "../../../types/rtk/order/order";
import { setOrder } from "../../../rtk/order/order-slice";

interface Props {
  datas: OffersState;
}

const SubscriptionChoice = ({ datas }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const basket = useAppSelector((state: RootState) => state.basket);
  const order = useAppSelector((state: RootState) => state.order);
  const order_ref = useAppSelector(
    (state: RootState) => state.order.reference
  );
  const [isModalOpenInformation, setIsModalOpenInformation] = useState(false);
  const [isModalOpenGiftCard, setIsModalOpenGiftCard] = useState(false);
  const [sendOrderUpdate] = useLazyPostOrderQuery();

  // useEffect(() => {
  //   if (basket.subscription === null) {
  //     handleOpenGiftCard();
  //   }
  // }, [basket]);

  const handleOpenInformation = () => setIsModalOpenInformation(true);
  const handleOpenGiftCard = () => setIsModalOpenGiftCard(true);
  const handleCloseInformation = () => setIsModalOpenInformation(false);
  const handleCloseGiftCard = () => setIsModalOpenGiftCard(false);

  const handleValidateGiftCard = async (giftcard: string | null) => {
    if (!order_ref) return false;
    const order_data: OrderBody = {
      offer_id: order.offer_id,
      options: order.options,
      giftcard: giftcard,
      promo: giftcard ? null : order.promo,
    };
    const resp = await sendOrderUpdate({ ref: order_ref, body: order_data });
    if (resp.isError) return false;
    setIsModalOpenGiftCard(false);
    await dispatch(setOrder(resp.data!));
    return true;
  };

  return (
    <Box className="SubscriptionChoice">
      <Box className="subscriptionSubtitleContainer">
        <Title
          variant="h1"
          className="subscriptionSubtitle"
          children={t("subscription:FIRST_PAGE.SUBSCRIPTION_CHOICE.TITLE")}
        />
        <Box className="subscriptionInfoLogo">
          <LogoSvg src={INFO} onClick={handleOpenInformation} />
        </Box>
      </Box>

      <SubscriptionList datas={datas} handleOpenGiftCard={handleOpenGiftCard} />
      <ModalSubscriptionInformations
        isModalOpen={isModalOpenInformation}
        handleClose={handleCloseInformation}
      />
      <ModalPromotionalCode
        isModalOpen={isModalOpenGiftCard}
        title={t("subscription:FIRST_PAGE.MODAL_GIFT_CARD.TITLE")}
        current={basket.giftcard}
        handleClose={handleCloseGiftCard}
        handleValidate={handleValidateGiftCard}
        mode="giftCard"
      />
    </Box>
  );
};

export default SubscriptionChoice;
