// import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
// import { useTranslation } from "react-i18next";

// import { AppRoutes } from "../../../routes/AppRoutes";
import Basket from "../../molecules/Basket/Basket";
// import { useAppDispatch, useAppSelector } from "../../../rtk/_config/hooks";
// import { RootState } from "../../../types/rtk/api-config/types";
// import { useLazyPostOrderQuery } from "../../../rtk/order/order";
// import { setOrder } from "../../../rtk/order/order-slice";
// import { OrderBody } from "../../../types/rtk/order/order";
// import { setBasket } from "../../../rtk/basket/basket";
// import { useEffect } from "react";

interface Props {
  headerStep: number;
  children?: JSX.Element;
}

const Footer = ({ headerStep, children }: Props) => {
  /*
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const basket = useAppSelector((state: RootState) => state.basket);
  const order = useAppSelector((state: RootState) => state.order);
  const order_ref = useAppSelector((state: RootState) => state.auth.order_reference);
  const [sendOrderUpdate, { isLoading }] = useLazyPostOrderQuery();
  
  useEffect(() => {
    if (headerStep !== 3) {
      dispatch(setBasket({...basket, isPaymentInformationsOk: false}));
      }
  }, [headerStep, basket, dispatch]);
  

  const isPayButton: boolean = (headerStep === 3);
  const isHipaySelected: boolean = (basket.paymentMethodSelected === 'card');
  const isPayAvailable: boolean = (isPayButton
    && basket.paymentMethodSelected !== undefined
    && ((isHipaySelected && basket.isPaymentInformationsOk) || !isHipaySelected)
  );

  async function update_order() {
    let body: OrderBody = {
      offer_id: undefined,
      options: undefined,
      giftcard: order.giftcard,
      promo: order.promo,
    };
    if (basket.subscription && basket.subscription?.id !== order.offer_id){
      body.offer_id = basket.subscription.id;
      body.options = undefined;
      body.giftcard = undefined;
    }
    if (basket.options && order.options === undefined) {
      body.options = basket.options.map((elm) => elm.id);
    }
    const { data, error } = await sendOrderUpdate({ref: order_ref!, body: body});
    if (error){
      navigate("/error");
    }
    if (data){
      await dispatch(
        setOrder({
          offer_id: data?.offer_id,
          options: data?.options,
          giftcard: data?.giftcard,
          promo: data?.promo,
        })
      )
    }
  }

  const switchNavigation = () => {
    switch (headerStep) {
      case 1:
        update_order().then(() => (
          navigate(AppRoutes.OPTIONS)
        ));
        break;
      case 2:
        update_order().then(() => (
          navigate(AppRoutes.PAYMENT)
        ));
        break;
      case 3:
        if (!isHipaySelected) navigate("/error");
        break;
      case 4:
        window.location.href = "weenect://app/home";
        break;
      default:
        break;
    }
  };

  const switchTextButton = (): string => {
    switch (headerStep) {
      case 1:
        return t("subscription:FIRST_PAGE.BUTTON");
      case 2:
        return t("subscription:SECONDE_PAGE.BUTTON");
      case 3:
        return t("subscription:THIRD_PAGE.BUTTON");
      case 4:
        return t("subscription:SUCCESS_PAGE.BUTTON");
      default:
        return "";
    }
  };
  */

  const switchTextBackgroundColor = (): string => {
    switch (headerStep) {
      case 2:
      case 3:
        return "#F9F9F9";

      default:
        return "white";
    }
  };

  return (
    <Box className="Footer" sx={{ background: switchTextBackgroundColor() }}>
      <Box className="footerContent">
        {(headerStep === 2 || headerStep === 3) && <Basket />}
        {children}
        {/* <StyledButton
          children={switchTextButton()}
          onClick={switchNavigation}
          id={isPayButton ? "hipay-submit-button" : undefined}
          type={isPayButton ? "submit" : undefined}
          form={isPayButton ? "hipay-form" : undefined}
          disabled={isPayButton && !isPayAvailable}
        />*/}
      </Box>
    </Box>
  );
};

export default Footer;
