import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrderBody, OrderRefState, OrderResponse, OrderState } from "../../types/rtk/order/order";
import { OffersState } from "../../types/rtk/subscription/subscription";
import store from "../_config/store";

const initialState: OrderState = {
  reference: null,
  expire_at: null,
  expired: null,
  status: null,
  offer_id: null,
  options: null,
  giftcard: null,
  promo: null,
  invalid: true,
};

function updateBody(state: OrderState, payload: OrderResponse | OrderBody) {
  state.offer_id = payload.offer_id;
  state.options = payload.options;
  state.giftcard = payload.giftcard;
  state.promo = payload.promo;
}

function updateRef(state: OrderState, payload: OrderResponse | OrderRefState) {
  state.reference = payload.reference;
  state.expire_at = payload.expire_at;
  state.status = payload.status;
  state.invalid = false;
}

export const OrderSlice = createSlice({
  name: "order",
  initialState: initialState,
  reducers: {
    setOrder: (state, action: PayloadAction<{order: OrderResponse, offers: OffersState}>) => {
      updateBody(state, action.payload.order);
      updateRef(state, action.payload.order);
    },
    setOrderBody: (state, action: PayloadAction<{order: OrderBody | OrderResponse, offers: OffersState}>) => {
      updateBody(state, action.payload.order);
    },
    setOrderRef: (state, action: PayloadAction<{order: OrderRefState | OrderResponse, offers: OffersState}>) => {
      updateRef(state, action.payload.order);
    },
    invalidOrder: (state, action: PayloadAction) => {
      state.invalid = true;
    },
  },
});

export const setOrder = (order: OrderResponse) => {
  const offers = store.getState().offers;
  return OrderSlice.actions.setOrder({order: order, offers: offers});
};

export const setOrderBody = (order: OrderBody | OrderResponse) => {
  const offers = store.getState().offers;
  return OrderSlice.actions.setOrderBody({order: order, offers: offers});
};

export const setOrderRef = (order: OrderRefState | OrderResponse) => {
  const offers = store.getState().offers;
  return OrderSlice.actions.setOrderRef({order: order, offers: offers});
};

export const invalidOrder = OrderSlice.actions.invalidOrder;

const OrderReducer = OrderSlice.reducer;
export default OrderReducer;
