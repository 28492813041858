import { useState } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import Title from "../../atoms/Title/Title";
import ModalPromotionalCode from "../../molecules/ModalSubscriptionGiftCard/ModalSubscriptionGiftCard";
// import { RootState } from "../../../types/rtk/api-config/types";
// import { useAppSelector } from "../../../rtk/_config/hooks";
import { OffersState } from "../../../types/rtk/subscription/subscription";
import Text from "../../atoms/Text/Text";
import PaymentList from "../../molecules/PaymentList/PaymentList";
import { useAppDispatch, useAppSelector } from "../../../rtk/_config/hooks";
import { RootState } from "../../../types/rtk/api-config/types";
import { OrderBody } from "../../../types/rtk/order/order";
import { useLazyPostOrderQuery } from "../../../rtk/order/order";
import { setOrder } from "../../../rtk/order/order-slice";

interface Props {
  datas?: OffersState;
  handleHipay: (response: any) => Promise<void>;
}

const PaymentChoice = ({ datas, handleHipay }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const order = useAppSelector((state: RootState) => state.order);
  const order_ref = useAppSelector(
    (state: RootState) => state.order.reference
  );
  const [isModalOpenPromoCode, setIsModalOpenPromoCode] = useState(false);
  const [sendOrderUpdate] = useLazyPostOrderQuery();

  const handleOpenPromoCode = () => setIsModalOpenPromoCode(true);
  const handleClosePromoCode = () => setIsModalOpenPromoCode(false);

  const handleValidatePromoCode = async (code: string | null) => {
    if (!order_ref) return false;
    const order_data: OrderBody = {
      ...order,
      promo: code,
    };
    const resp = await sendOrderUpdate({ ref: order_ref, body: order_data });
    if (resp.isError) return false;
    setIsModalOpenPromoCode(false);
    await dispatch(setOrder(resp.data!));
    return true;
  };

  return (
    <Box className="PaymentChoice">
      <Box className="paymentSubtitleContainer">
        <Title
          variant="h1"
          className="paymentSubtitle"
          children={t("subscription:THIRD_PAGE.PAYMENT_CHOICE.TITLE")}
        />
        <Text
          className="paymentTextSubtitle"
          children={t("subscription:THIRD_PAGE.SUBTITLE")}
        />
      </Box>

      <PaymentList handleOpenGiftCard={handleOpenPromoCode} handleHipay={handleHipay} />

      {(order.giftcard === null) ? <ModalPromotionalCode
        isModalOpen={isModalOpenPromoCode}
        title={t("subscription:THIRD_PAGE.MODAL.TITLE")}
        placeholder="Ex : PROMOCODE"
        current={order.promo}
        handleClose={handleClosePromoCode}
        handleValidate={handleValidatePromoCode}
        mode="promoCode"
      /> : null}
    </Box>
  );
};

export default PaymentChoice;
