import { ReactSVG } from "react-svg";

interface Props {
  src: string;
  className?: string;
  onClick?: () => void;
}

const LogoSvg = ({ src, className, onClick }: Props) => (
  <ReactSVG className={`LogoSvg ${className}`} src={src} onClick={onClick} />
);

export default LogoSvg;
