import { Box } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import LogoSvg from "../../atoms/LogoSvg/LogoSvg";
import FAIL from "../../../assets/images/FAIL.svg";
import Text from "../../atoms/Text/Text";
import Title from "../../atoms/Title/Title";
import Footer from "../../templates/Footer/Footer";
import Header from "../../templates/Header/Header";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import { useAppSelector } from "../../../rtk/_config/hooks";
import { RootState } from "../../../types/rtk/api-config/types";
import { AppRoutes } from "../../../routes/AppRoutes";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";
import { useLazyRetryOrderQuery } from "../../../rtk/order/order";

const Cancel = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const order_ref = useAppSelector(
    (state: RootState) => state.order.reference
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [retryOrder] = useLazyRetryOrderQuery();

  if (order_ref === null) {
    navigate(AppRoutes.BASE_ROUTE);
  }
  
  const send_retry = async () => {
    if (order_ref === null) return;
    setIsLoading(true);
    const resp = await retryOrder({ref: order_ref!});
    if (resp.isError) navigate(AppRoutes.BASE_ROUTE);
    else {
      if (resp.data!.expired) navigate(AppRoutes.BASE_ROUTE);
      else navigate(AppRoutes.PAYMENT);
    }
  }

  return (
    <Box className="App">
      <Header headerStep={4} title={t("subscription:CANCEL_PAGE.TITLE")} />
      <Box className="Cancel">
        <Box className="content">
          <LogoSvg className="Logos" src={FAIL} />
          <Title
            variant="h1"
            children={t("subscription:CANCEL_PAGE.TITLE") + "."}
          />
          <Text children={t("subscription:CANCEL_PAGE.TEXT")} />
        </Box>
      </Box>
      <Footer headerStep={4}>
        {isLoading ? <SpinnerLoader /> : <StyledButton
          children={t("subscription:CANCEL_PAGE.BUTTON")}
          onClick={() => {send_retry()}}
        />}
      </Footer>
    </Box>
  );
};

export default Cancel;
