import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SettingsState, SettingsStateChange } from "../../types/rtk/settings/settings";

const initialState: SettingsState = {
  darkmode: false,
  language: undefined,
};

const SettingSlice = createSlice({
  name: "settings",
  initialState: initialState,
  reducers: {
    setSettings: (state, action: PayloadAction<SettingsStateChange>) => {
      if (action.payload.darkmode !== undefined) state.darkmode = action.payload.darkmode;
      state.language = action.payload.language || state.language;
    },
    resetLanguage: (state, action: PayloadAction<void>) => {
      state.language = undefined;
    },
  },
});

export const { setSettings, resetLanguage } = SettingSlice.actions;
const SettingsReducer = SettingSlice.reducer;
export default SettingsReducer;
