import { config } from "../../config";

//@ts-ignore
export const hipayInstance = HiPay({
  username: config.HIPAY_ACCOUNT,
  password: config.HIPAY_TOKEN,
  environment: config.HIPAY_STAGE ? "stage" : "production",
  lang: "fr",
});


export const configHipay = {
  multi_use: true,
  fields: {
    cardNumber: {
      selector: "hipay-card-number",
      placeholder: "0123 4567 8901 2345",
    },
    expiryDate: {
      selector: "hipay-expiry-date",
      placeholder: "MM/YY",
    },
    cvc: {
      selector: "hipay-cvc",
      placeholder: "CVV",
      helpButton: true,
    },
    cardHolder: {
      selector: "hipay-card-holder",
      placeholder: "Votre nom",
      uppercase: true,
    },
  },
};
