import { Box } from "@mui/material";

import SubscriptionCard from "../SubscriptionCard/SubscriptionCard";
import { OffersState } from "../../../types/rtk/subscription/subscription";
import { RootState } from "../../../types/rtk/api-config/types";
import { useAppSelector } from "../../../rtk/_config/hooks";

interface Props {
  datas: OffersState;
  handleOpenGiftCard: () => void;
}

const SubscriptionList = ({ datas, handleOpenGiftCard}: Props) => {
  const basket = useAppSelector((state: RootState) => state.basket);
  const giftcardSelected: boolean = !!basket.giftcard;

  return (
    <Box className="SubscriptionList">
      {datas.subscriptionsData.map((obj, i) => {
        return (
          <SubscriptionCard
            key={i}
            index={i}
            data={obj}
            isActive={!giftcardSelected && basket.subscription?.id === obj.id}
          />
        );
      })}
      <SubscriptionCard
        isActive={giftcardSelected}
        onSelect={handleOpenGiftCard}
      />
    </Box>
  );
};

export default SubscriptionList;
