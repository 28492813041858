import { OrderBody, OrderResponse } from "../../types/rtk/order/order";
import { OrderPaymentBody } from "../../types/rtk/payment/payment";
import { apiSlice } from "../_api/api-slice";

const orderEndpoint = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getOrder: build.query<OrderResponse, {ref?: string }>({
      query: ({ref} = {ref: undefined}) => ({
        url: (ref) ? `/order/${ref}`: '/order',
        method: "get",
      }),
    }),
    postOrder: build.query<OrderResponse, {ref: string, body: OrderBody}>({
      query: ({ref, body}) => ({
        url: `/order/${ref}`,
        method: "post",
        body
      }),
    }),
    payOrder: build.query<string, {ref: string, body: OrderPaymentBody}>({
      query: ({ref, body}) => ({
        url: `/order/${ref}/pay`,
        method: "post",
        body
      }),
    }),
    validateOrder: build.query<string, {ref: string}>({
      query: ({ref}) => ({
        url: `/order/${ref}/validate`,
        method: "post",
      }),
    }),
    retryOrder: build.query<OrderResponse, {ref: string}>({
      query: ({ref}) => ({
        url: `/order/${ref}/retry`,
        method: "post",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetOrderQuery,
  useLazyGetOrderQuery,
  usePostOrderQuery,
  useLazyPostOrderQuery,
  useLazyPayOrderQuery,
  useLazyValidateOrderQuery,
  useLazyRetryOrderQuery
} = orderEndpoint;

