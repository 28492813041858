import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import LogoSvg from "../../atoms/LogoSvg/LogoSvg";
import QUESTION_MARK from "../../../assets/images/QUESTION-MARK.svg";
import Text from "../../atoms/Text/Text";
import Title from "../../atoms/Title/Title";
import Footer from "../../templates/Footer/Footer";
import Header from "../../templates/Header/Header";
import StyledButton from "../../atoms/StyledButton/StyledButton";

const Unknown = () => {
  const { t } = useTranslation();

  return (
    <Box className="App">
      <Header headerStep={4} title={t("subscription:UNKNOWN_PAGE.TITLE")} />
      <Box className="Unknown">
        <Box className="content">
            <LogoSvg className="Logos" src={QUESTION_MARK} />
          <Title
            variant="h1"
            children={t("subscription:UNKNOWN_PAGE.TITLE")}
          />
          <Text children={t("subscription:UNKNOWN_PAGE.TEXT")} />
        </Box>
      </Box>
      <Footer headerStep={4}>
        <StyledButton
          children={t("subscription:UNKNOWN_PAGE.BUTTON")}
          onClick={() => (window.close())}
        />
      </Footer>
    </Box>
  );
};

export default Unknown;
