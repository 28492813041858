import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";

import LogoSvg from "../../atoms/LogoSvg/LogoSvg";
import ActiveButton from "../../../assets/images/RADIO-BUTTON-ACTIVE.svg";
import InnactiveButton from "../../../assets/images/RADIO-BUTTON-INNACTIVE.svg";
import Title from "../../atoms/Title/Title";
import PaymentForm from "../PaymentForm/PaymentForm";
// import { useAppDispatch, useAppSelector } from "../../../rtk/_config/hooks";
// import { setBasket } from "../../../rtk/basket/basket";
// import { RootState } from "../../../types/rtk/api-config/types";

interface Props {
  title: string;
  isActive: boolean;
  setIsActive: (mode: string) => void;
  mode: 'card' | 'bank';
  handleHipay?: (response: any) => Promise<void>;
}

const PaymentCard = ({ title, isActive, setIsActive, mode, handleHipay }: Props) => {
  //   const dispatch = useAppDispatch();
  //   const basket = useAppSelector((state: RootState) => state.basket);

  const handleSetActive = () => {
    setIsActive(mode);
  };

  return (
    <Box
      className={`PaymentCard ${isActive && "isPaymentCardActive"}`}
      onClick={handleSetActive}
    >
      {mode === 'card' ? (
        <Accordion className="Accordion" expanded={isActive}>
          <AccordionSummary id="panel-header" aria-controls="panel-content">
            <Box className="card">
              <Box className="paymentChip">
                <LogoSvg src={isActive ? ActiveButton : InnactiveButton} />
              </Box>
              <Box className="paymentTitle">
                <Title variant="h1" children={title} />
              </Box>
              <Box className={"cardLogos"} />
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {isActive && <PaymentForm handleHipay={handleHipay!}/>}
          </AccordionDetails>
        </Accordion>
      ) : (
        <Box className="bank">
          <Box className="paymentChip">
            <LogoSvg src={isActive ? ActiveButton : InnactiveButton} />
          </Box>
          <Box className="paymentTitle">
            <Title variant="h1" children={title} />
          </Box>
          <Box className={"cardLogos"} />
        </Box>
      )}
    </Box>
  );
};

export default PaymentCard;
