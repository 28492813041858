import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import {
  // Subscription,
  SubscriptionVariants,
} from "../../../types/rtk/subscription/subscription";
import LogoSvg from "../../atoms/LogoSvg/LogoSvg";
import ActiveButton from "../../../assets/images/RADIO-BUTTON-ACTIVE.svg";
import InnactiveButton from "../../../assets/images/RADIO-BUTTON-INNACTIVE.svg";
import Title from "../../atoms/Title/Title";
import Chip from "../../atoms/Chip/Chip";
import Text from "../../atoms/Text/Text";
import { getYearByMonth } from "../../../helpers/getYearByMonth";
import { RootState } from "../../../types/rtk/api-config/types";
import { useAppDispatch, useAppSelector } from "../../../rtk/_config/hooks";
import { setBasket } from "../../../rtk/basket/basket";

interface Props {
  data?: SubscriptionVariants;
  index?: number;
  isActive: boolean;
  onSelect?: () => void;
}

const SubscriptionCard = ({ data, index, isActive, onSelect }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const basket = useAppSelector((state: RootState) => state.basket);

  // const yearlyPeriod = getYearByMonth(data?.period || 0);
  const giftcard_reduction = Math.round((basket.giftcard_ratio || 0) * 100);

  const getChip = (period: number) => {
    switch (period) {
      case 1:
        return t("subscription:FIRST_PAGE.SUBSCRIPTION_CHOICE.CHIP_ONE_YEAR");
      case 3:
        return t(
          "subscription:FIRST_PAGE.SUBSCRIPTION_CHOICE.CHIP_THREE_YEAR",
          {
            count: data ? Math.round((1 - (data.price.savings!.ratio || 0)) * 100) : 0,
          }
        );
      default:
        return t("subscription:FIRST_PAGE.SUBSCRIPTION_CHOICE.CHIP_ONE_MONTH");
    }
  };

  const handleSetActive = () => {
    onSelect ? onSelect() : (
      data
        ? dispatch(setBasket({ ...basket, subscription: data, giftcard: null }))
        : dispatch(setBasket({ ...basket, subscription: null }))
    );
  };

  return (
    <Box
      className={`SubscriptionCard ${isActive && "isSubscriptionCardActive"}`}
      onClick={handleSetActive}
    >
      <Box className="subscriptionChip">
        <LogoSvg src={isActive ? ActiveButton : InnactiveButton} />
      </Box>
      <Box className="subscriptionTitle">
        {data && data.period && (
          <Chip
            text={getChip(getYearByMonth(data.period))}
            isActive={index === 0}
          />
        )}
        <Title variant="h1">
          {(data) ? (
            (data.period > 1) ? 
            t("subscription:FIRST_PAGE.SUBSCRIPTION_CHOICE.SUBSCRIPTION_TITLE", { count: getYearByMonth(data.period) })
            : t("subscription:FIRST_PAGE.SUBSCRIPTION_CHOICE.SUBSCRIPTION_TITLE_MONTHLY")
          ) : (
            (basket.giftcard) ?
            `${t("subscription:FIRST_PAGE.MODAL_GIFT_CARD.INPUT_LABEL")} : ${basket.giftcard}`
            : t("subscription:FIRST_PAGE.SUBSCRIPTION_CHOICE.SUBSCRIPTION_TITLE_GIFT_CARD")
          )}
        </Title>
        {(!data) && basket.giftcard && basket.subscription &&
          <Title variant="h2">
          {basket.subscription.period > 1
              ? t("subscription:FIRST_PAGE.SUBSCRIPTION_CHOICE.SUBSCRIPTION_TITLE",{
                    count: getYearByMonth(basket.subscription.period),
                })
              : t("subscription:FIRST_PAGE.SUBSCRIPTION_CHOICE.SUBSCRIPTION_TITLE_MONTHLY")
          } {(giftcard_reduction < 100) ? t("subscription:FOR_MINUS_X_PERCENT", {percent: giftcard_reduction})
                                        : t("subscription:FREE")}
          </Title>}
      </Box>
      {/* TODO translation price */}
      {data && (
        <Box className="subscriptionPrice">
          {data && (
            <>
              <Box className="prices">
                <Title variant="h1" className="picesColor">
                  {`${(data.price.amount / data.period).toFixed(2)}€ `}
                </Title>
                <Title variant="h1">{` / ${t("subscription:MONTH")}`}</Title>
              </Box>
              {data.price.amount && getYearByMonth(data.period) >= 1 && (
                <Text
                  children={
                    data.period === 12
                      ? t("subscription:FIRST_PAGE.BY_YEAR", {
                          price: data.price.amount + "€",
                        })
                      : t("subscription:FIRST_PAGE.FOR_YEAR", {
                          price: data.price.amount + "€",
                          period: getYearByMonth(data.period),
                        })
                  }
                />
              )}
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SubscriptionCard;
