/**
 * See /.env
 */
export interface IConfig {
  REACT_APP_APIV4_BASE_URL: string;
  HIPAY_ACCOUNT: string;
  HIPAY_TOKEN: string;
  HIPAY_STAGE: boolean
}

export const config: IConfig = {
  REACT_APP_APIV4_BASE_URL: process.env.REACT_APP_APIV4_BASE_URL || "",
  HIPAY_ACCOUNT: process.env.REACT_APP_HIPAY_ACCOUNT || "94695882.stage-secure-gateway.hipay-tpp.com",
  HIPAY_TOKEN: process.env.REACT_APP_HIPAY_TOKEN || "Test_sfMWWpacIWXBH3RDKAAcdUJ2",
  HIPAY_STAGE: !(["false", "no"].includes((process.env.REACT_APP_HIPAY_STAGE ?? "true").toLowerCase())),
};
