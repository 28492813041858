export enum AppRoutes {
  BASE_ROUTE = "/",
  SUBSCRIPTIONS = "/subscriptions",
  OPTIONS = "/options",
  PAYMENT = "/payment",
  SUCCESS = "/success",
  FAIL = "/fail",
  CANCEL = "/cancel",
  UNKNOWN = "/unknown",
  PENDING = "/Pending",
}
