import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import Modal from "../../atoms/Modal/Modal";
import CROSS from "../../../assets/images/CROSS.svg";
import LogoSvg from "../../atoms/LogoSvg/LogoSvg";
import InputText from "../../atoms/InputText/InputText";
import Title from "../../atoms/Title/Title";
import Text from "../../atoms/Text/Text";
import StyledButton from "../../atoms/StyledButton/StyledButton";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";

interface Props {
  isModalOpen: boolean;
  title: string;
  placeholder?: string;
  current: string | null;
  handleClose: () => void;
  handleValidate: (value: string | null) => Promise<boolean>;
  mode: "giftCard" | "promoCode";
}

const ModalPromotionalCode = ({
  isModalOpen,
  title,
  placeholder,
  current,
  handleClose,
  handleValidate,
  mode,
}: Props) => {
  const { t } = useTranslation();
  const [code, setCode] = useState(current ?? "");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleValidateButton = () => {
    setIsLoading(true);
    const cleaned_code = code ? code.trim() : null;
    handleValidate(cleaned_code).then((success: boolean) => {
      if (!success) setIsError(true);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (current) {
      setCode(current);
    }
  }, [isModalOpen, current]);

  return (
    <Modal open={isModalOpen} onClose={handleClose}>
      <Box className="ModalSubscriptionGiftCard">
        <Box className="modalAlignement" sx={{ padding: "20px" }}>
          <Box className="closingCross">
            <LogoSvg src={CROSS} className="logo" onClick={handleClose} />
          </Box>
          <Box className="blocs">
            <Title variant="h1" children={title} />
          </Box>
          <Box className="blocs">
            <InputText
              label={title}
              value={code}
              onChange={(value) => {
                setIsError(false);
                return setCode(value);
              }}
              placeHolder={placeholder ?? "Ex : AA00-BB11-CC22"}
              className={isError ? "error" : undefined}
            />
            {isError ? (
              <Text className="error">
                {mode === "giftCard"
                  ? t("subscription:FIRST_PAGE.MODAL_GIFT_CARD.ERROR")
                  : t("subscription:THIRD_PAGE.MODAL.ERROR")}
              </Text>
            ) : null}
          </Box>
          <Box className="blocs">
            {isLoading ? (
              <SpinnerLoader />
            ) : (
              (code || current === null) ?
              <StyledButton
                children={t("subscription:FIRST_PAGE.MODAL_GIFT_CARD.BUTTON")}
                onClick={handleValidateButton}
                disabled={!code || isError}
              />
              :<StyledButton
                children={t("subscription:FIRST_PAGE.MODAL_GIFT_CARD.DELETE_BUTTON")}
                onClick={handleValidateButton}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalPromotionalCode;
